import React, { useEffect, useRef } from "react"
import { graphql, navigate } from "gatsby"
import BaseLayout from "./baseLayout"
import { object } from "prop-types"
import { Newsbar } from "../components/Newsbar"
import "../styles/main.scss"
import { eyeBrowText } from "../utilities/eyebrowText"
import getTemplateData from "../utilities/getTemplateData"
import Accordion from "../components/Accordion"
import {
  addCircleGraph,
  addDownloads,
  updatePreText,
  addStaticAccordion,
  getAccordionDetails,
  updatePullQuote,
  getContentForCircleGraph,
} from "../utilities/updateContent"
import CircleGraph from "../components/CircleGraph"

export const query = graphql`
  query ($slug: String!) {
    ecsPage(slug: { eq: $slug }) {
      content
      id
      slug
    }

    json(fields: { slug: { eq: $slug } }) {
      fields {
        canonicalUrl
      }
      components {
        type
        content
        options
      }
    }

    allNewsJson {
      edges {
        node {
          id
          date
          href
          snippet
        }
      }
    }

    allDownloadsJson {
      edges {
        node {
          id
          title
          href
        }
      }
    }

    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }
  }
`

const ArticlePage = ({ data, location }) => {
  const graphRef = useRef(null)
  const acccRef = useRef(null)
  const accordionDetails = []
  const circleGraphSlices = []

  const { headData, components, sitewideMessages, globalHeader } =
    getTemplateData(data, location)

  useEffect(() => {
    // Adds circle graph in second tab of company/business-strategy
    addCircleGraph(data, graphRef)

    // Adds a download link that lets you download PDF reports
    addDownloads(data)

    // Function to dynamically injest the component from bottom to the space that has corresponding preText
    updatePreText(data)

    // Dynamically adds static accordion to the section and removed CMS accordion
    addStaticAccordion(data, acccRef)

    // Manipulates Pull Quote so that bullet points are <ul> instead of just having the bullet point at beginning of line
    updatePullQuote(data)
  }, [])

  // Gets data of CMS accordion to be used in static accordion
  getAccordionDetails(data, accordionDetails)
  // Gets data from CMS for circle graph and then create it in array
  getContentForCircleGraph(
    data,
    circleGraphSlices,
    "business-strategy-and-competitive-advantages"
  )

  const showPreHeader = () => {
    const slug = data.ecsPage.slug
    if (
      slug.includes("illustrative-initiatives") &&
      slug !== "illustrative-initiatives"
    ) {
      return (
        <section className="pre-heading-section">
          <a className="ii-back-btn" onClick={() => navigate(-1)}>
            <span>&#8592;</span>&nbsp;&nbsp;Back
          </a>
          <p
            className="eye-brow-subtitle"
            dangerouslySetInnerHTML={{
              __html: eyeBrowText[slug.split("/")[1]],
            }}
          ></p>
        </section>
      )
    }
    return null
  }

  useEffect(() => {
    // remove circle graph texts that were given in DCM
    if (
      data.ecsPage.slug.includes(
        "company/business-strategy-and-competitive-advantages"
      )
    ) {
      const tdsRows = document
        ?.querySelector("#content-body")
        .querySelectorAll(".tds-row")
      for (var i = 6; i < 18; i++) {
        tdsRows[i].remove()
      }
    }
    // this prevents pushing hash change to history and scrolls to top of the page
    document?.addEventListener("click", function (e) {
      if (e?.target?.attributes?.href?.value === "#super-hero-id") {
        e.preventDefault()
        document?.getElementById("super-hero-id")?.scrollIntoView()
      }
    })
  }, [])

  return (
    <>
      <BaseLayout
        head={headData}
        disclaimers={components["Disclaimer"]}
        sitewideMessages={sitewideMessages}
        globalHeader={globalHeader}
      >
        {components["Carousel"] && (
          <div className="featured-area">
            <div className="article-hero">{components["Carousel"]}</div>
            <Newsbar>
              {components["stockticker"]}
              {components["highlight"]}
              {components["downloads"]}
              {components["news"]}
              {components["codes"]}
            </Newsbar>
            <section>{components["valueDrivers"]}</section>
            {components["Dynamic_Cta"]}
          </div>
        )}
        <section id="content-link" role="main" tabIndex="-1">
          {showPreHeader()}
          <div className="ls-area article" id="content-body">
            {components["Body_Content"]}
            {circleGraphSlices?.length > 0 && (
              <section ref={graphRef}>
                <CircleGraph
                  centerTitle="Our Drivers of Sustained Value"
                  slices={circleGraphSlices}
                  slug={data.ecsPage.slug}
                />
              </section>
            )}
            {accordionDetails?.length > 0 && (
              <section ref={acccRef}>
                <Accordion
                  accordions={accordionDetails}
                  heading="INNOVATIVE PRODUCTS & SERVICES"
                />
              </section>
            )}
            {components["Link_List"] && (
              <div className="tds-row">{components["Link_List"]}</div>
            )}
            {components["Page_Promo"]}
          </div>
        </section>
        {components["Footer_Cap"]}
      </BaseLayout>
    </>
  )
}

export default ArticlePage

ArticlePage.propTypes = {
  data: object,
  location: object,
}
