const travelersInstitute = "TRAVELERS INSTITUTE<sup>&reg;</sup>"
export const eyeBrowText = {
  "ibhs-sponsorship": "IBHS SPONSORSHIP",
  "protecting-our-customers-from-increased-wildfire-risk":
    "WILDFIRE DEFENSE SERVICES",
  "she-travels": "SHE TRAVELS<sup>&reg;</sup>",
  "boost-engagement": "OFFICE RENOVATIONS",
  "travelers-fortifies": "TRAVELERS FORTIFIES COMMUNITIES",
  "distracted-driving": travelersInstitute,
  "travelers-institute": travelersInstitute,
  "conciergeclaim-nurse": "TRAVELERS MEDICAL ADVANTAGE<sup>&reg;</sup>",
  "small-business": "SMALL BUSINESS SUPPORT",
  "minority-business": "MINORITY BUSINESS DEVELOPMENT INSTITUTE PARTNERSHIP",
  "fostering-commitment":
    "ENHANCING OUR COMMITMENT TO PEOPLE WITH DISABILITIES",
  "travelers-championship": "TRAVELERS CHAMPIONSHIP<sup>&reg;</sup>",
  "medical-advantage": "TRAVELERS MEDICAL ADVANTAGE<sup>&reg;</sup>",
  "greening-claims": "GREENING CLAIMS",
  "thought-leadership-on-disaster-preparedness": travelersInstitute,
  "transforming-tomorrows-workforce": "TRAVELERS EDGE<sup>&reg;</sup>",
  "smallbusiness-advocacy": "SMALL BUSINESS – BIG OPPORTUNITY<sup>&reg;</sup>",
  "supporting-those-who-serve": "COMMITTED TO MILITARY VETERANS",
  "mental-health": "MAKING MENTAL HEALTH A PRIORITY",
  "travelers-institute-university-tours": travelersInstitute,
  "training-world-class-claim-professionals": "CLAIM UNIVERSITY",
  "understanding-cyber-risks": "2023 TRAVELERS RISK INDEX",
  "investing-next-generation-of-talent":
    "SUPPORTING FUTURE LEADERS IN INSURANCE",
  "partnering-for-a-purpose-helping-to-reforest-america":
    "AMERICAN FORESTS PARTNERSHIP",
  "finding-innovative-ways-compensation-related-injuries":
    "INNOVATON IN WORKERS COMPENSATION RECOVERY",
  "national-cybersecurity-awareness-month": "CYBERSECURITY",
  "leveraging-technology-to-improve-worker-safety": "INNOVATION FOR SAFETY",
  "fostering-financial-literacy-and-insurance-know-how":
    "JUNIOR ACHIEVEMENT INSURANCE LEARNING LABS",
  "catastrophe-planning-and-response": "NATIONAL CATASTROPHE CENTER",
  "driving-opportunities": "COMMUNITY SERVICE VEHICLE DONATIONS",
  "inspiring-girls-to-be-strong-smart-and-bold":
    "GIRLS&rsquo; LEADERSHIP SUMMITS",
  "growing-tech-talent": "GIRLS WHO CODE",
  "international-association-of-black-actuaries":
    "DEVELOPING A MORE DIVERSE PIPELINE OF ACTUARIAL TALENT",
  "leveraging-telematics-to-encourage-safe-driving":
    "INTELLIDRIVE<sup>&reg;</sup>",
  "travelers-artspeaks": "TRAVELERS ART SPEAKS",
  "enhancing-the-experience-of-injured-employees":
    "CUSTOMCARE<sup>&#8480;</sup> AND WORKERS COMPENSATION INJURY RECOVERY",
  "thought-leadership-programming":
    "WEDNESDAYS WITH WOODWARD<sup>&reg;</sup> WEBINAR SERIES",
  "travelers-europe-forest-takes-shape": "ENVIRONMENTAL INITIATIVES IN EUROPE",
  "simplifying-the-customer-experience":
    "REDESIGNED MYTRAVELERS<sup>&reg;</sup> WEB EXPERIENCE",
  "adding-value-for-agents-and-brokers": "TOOLKITPLUS",
  "inspiring-next-generation-inventors": "CONNECTICUT INVENTION CONVENTION",
  "theres-no-place-like-home": "FEATURED SERVICE PROJECT",
  "oppnet-uaspire-hartford-promise": "INVESTING IN COLLEGE ACCESS AND SUCCESS",
}
