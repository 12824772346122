import React, { useEffect } from "react"
import { array, string } from "prop-types"
import "./Accordion.scss"

export default function Accordion({ accordions, heading }) {
  useEffect(() => {
    const checkBoxes = document.querySelectorAll("input[type='checkbox']")
    checkBoxes.forEach((checkBox) => {
      checkBox.checked = false
    })
  }, [])
  return (
    <>
      {heading && <h3 className="custom-h3">{heading}</h3>}
      {accordions.map((accordion, index) => {
        return (
          <div key={index}>
            <input
              id={"collapsible" + index}
              className="toggle"
              type="checkbox"
            />
            <label htmlFor={"collapsible" + index} className="lbl-toggle">
              {accordion.title}
            </label>
            <div className="collapsible-content">
              <div
                className="content-inner"
                dangerouslySetInnerHTML={{ __html: accordion.content }}
              ></div>
            </div>
          </div>
        )
      })}
    </>
  )
}

Accordion.propTypes = {
  accordions: array,
  heading: string,
}
